import { Item } from './components/Item';

function App() {
    return (
        <div data-testid="App">
            <Item />
        </div>
    );
}

export default App;
